// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// $gp-palette: (
//   50: #fbfaf9,
//   100: #f3f1ed,
//   200: #e7e2da,
//   300: #d6d0c2,
//   400: #502717,
//   500: #371b10,
//   600: #24110a,
//   700: white,
//   800: #212529,
//   900: white,
//   contrast: (
//     50: rgba(black, 0.5),
//     100: rgba(black, 0.87),
//     200: rgba(black, 0.87),
//     300: white,
//     400: white,
//     500: white,
//     600: white,
//     700: white,
//     800: rgba(black, 0.87),
//     900: white,
//   ),
// );
:root{
  --50: #E5FFF6,
  --100: #C9FFEC,
  --200: #99DFC1,
  --300: #3AA06B,
  --400: #11562F,
  --500: #0A4020,
  --600: #032911,
  --700: #001D09,
  --800: #212529,
  --900: #000,
}

$gp-palette: (
  50: var(--50),
  100: var(--100),
  200: var(--200),
  300: var(--300),
  400: var(--400),
  500: var(--500),
  600: var(--600),
  700: var(--700),
  800: var(--800),
  900: var(--900),
  contrast: (
    50: rgba(black, 0.5),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: rgba(black, 0.87),
    900: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gmall_white-label_admin-primary: mat.define-palette($gp-palette);
$gmall_white-label_admin-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$gmall_white-label_admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gmall_white-label_admin-theme: mat.define-light-theme(
  (
    color: (
      primary: $gmall_white-label_admin-primary,
      secondary: mat.get-color-from-palette($gp-palette, 200),
      accent: $gmall_white-label_admin-accent,
      warn: $gmall_white-label_admin-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gmall_white-label_admin-theme);
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css");
// regular style toast
@import "ngx-toastr/toastr";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

/* You can add global styles to this file, and also import other style files */

.dense-4 {
  @include mat.all-component-densities(-4);
}

* {
  font-family: "Manrope", sans-serif;
}
html,
body {
  height: 100%;
}
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

ng-icon {
  overflow: visible !important;
}

file-upload {
  background-color: #f8f9fa !important;
  // height: 200px;
  min-height: 200px !important;
  // max-height: 400px;
  display: table;
  width: 100%;
  //   border: dotted 1px #aaa;
  border: 1px dashed var(--Gray-300, #ced4da);
  border-radius: 8px;
  outline-color: #f8f9fa !important;
  padding: 0 !important;
  .upload-text {
    text-align: center;

    font-size: 16px;
    color: mat.get-color-from-palette($gp-palette, 400);
    font-weight: 700;
    ng-icon {
      margin-bottom: 5px;
      font-size: 24px;
    }
    span {
      font-size: 14px;
      color: black;
      font-weight: 400;
    }
  }

  .text-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
}

a {
  text-decoration: none;
  // color: mat.get-color-from-palette($gp-palette, 500) !important;
  color: black;
  &:hover {
    color: mat.get-color-from-palette($gp-palette, 300) !important;
  }
}
.mdc-card {
  border: solid 1px #eee;
  box-shadow: 0 0 0 !important;

  .mdc-card__actions {
    button + button {
      margin-left: 8px;
    }
  }
}
mat-card-actions {
  padding: 8px 16px 24px 16px !important;
}
.mat-drawer.mat-drawer-side {
  width: 250px;
  background-color: mat.get-color-from-palette($gp-palette, 400);
  height: 100vh;
  border-right: 0;

  a {
    text-decoration: none;
    display: block;
  }
  .mdc-list {
    padding: 0;
  }
  .mdc-list-item.mdc-list-item--with-one-line {
    height: 56px;
    a {
      color: black;
    }
  }
  .mdc-list-item.mdc-list-item--with-one-line.selected-option {
    border-right: solid 4px mat.get-color-from-palette($gp-palette, 400);
    a {
      color: mat.get-color-from-palette($gp-palette, 400);
      font-weight: 500;
    }
  }
  .mdc-list-item.mdc-list-item--with-one-line:hover {
    border-right: solid 4px mat.get-color-from-palette($gp-palette, 400);
    a {
      color: mat.get-color-from-palette($gp-palette, 400);
      font-weight: 500;
    }
  }
  .logo {
    padding: 20px;
  }
}
.mat-drawer-container {
  height: 100vh;
}
.mat-drawer-content {
  padding: 20px;
}

h2 {
  color: mat.get-color-from-palette($gp-palette, 400);
  font-weight: 700 !important;
  font-size: 24px !important;
  // margin-bottom: 40px;
}

h3 {
  color: mat.get-color-from-palette($gp-palette, 400);
  font-weight: 700 !important;
  font-size: 20px !important;
  // margin-bottom: 40px;
}

h4 {
  color: mat.get-color-from-palette($gp-palette, 800);
  font-weight: 700 !important;
  font-size: 16px !important;
  // margin-bottom: 40px;
}

mat-label {
  font-weight: 700;
  size: 18px;
  color: #212529;
  margin-bottom: 10px;
}
// button
.mdc-button {
  min-width: 0 !important;
  ng-icon {
    vertical-align: auto !important;
  }
}
// form
.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff !important;
}

.mat-mdc-form-field-infix {
  display: flex;

  ng-icon + input {
    margin-left: 8px;
  }
}

//dialog
.mdc-dialog__title + .mdc-dialog__content,
.mdc-dialog__header + .mdc-dialog__content {
  padding-top: 20px;
}
.mat-mdc-dialog-container {
  h2 {
    font-size: 24px !important;
    font-weight: 700 !important;
    display: flex;
    justify-content: space-between;
    color: #100804;
    padding: 20px 24px 20px 24px;
  }
  .mat-mdc-dialog-actions {
    padding: 20px 24px 20px 24px;
  }
  .mdc-dialog__content {
    p {
      font-size: 16px;
      letter-spacing: 0 !important;
      line-height: normal;
      color: mat.get-color-from-palette($gp-palette, 800);
    }
  }
}
.information-dialog {
  .mdc-list-item__content {
    // display: block;
  }
}

// tabs
.mat-mdc-tab-header {
  margin-bottom: 20px;
}

//expansion-panel
.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  border: solid 1px mat.get-color-from-palette($gp-palette, 300);
  margin-bottom: 5px;
}
.mat-expansion-panel-header {
  font-weight: 700;
  padding-left: 10px;
}
.mat-column-actions .mat-mdc-icon-button.mat-mdc-button-base {
  width: 30px;
  height: 30px;
  padding: 4px;
  font-size: 18px;
}
.mat-mdc-outlined-button:not(:disabled) {
  border-color: mat.get-color-from-palette($gp-palette, 400) !important;
}
.mat-column-actions {
  text-align: right !important;

  button + button {
    margin-left: 3px;
  }
}
mat-card {
  overflow: hidden;
}

mat-card-header {
  button + button {
    margin-left: 10px;
  }
  mat-form-field + button {
    margin-left: 10px;
  }
}

.mat-mdc-row:nth-child(even) {
  background-color: #fff;
}

.mat-mdc-row:nth-child(odd) {
  background-color: #f8f9fa;
}
.alert-primary {
  background-color: #fff;
  color: mat.get-color-from-palette($gp-palette, 400);
  border-color: mat.get-color-from-palette($gp-palette, 300);
  svg {
    fill: mat.get-color-from-palette($gp-palette, 200);
  }
}
.mdc-list-item__secondary-text {
  margin-top: 10px !important;
  color: #343a40 !important;
}
.mat-mdc-form-field-infix {
  display: flex;
}

ngx-material-timepicker-content {
  z-index: 10000 !important;
}

.card-list-item {
  border: 1px solid #dee2e6 !important;
  border-radius: 8px !important;
  margin-bottom: 20px;
}
.item-card-info {
  a{
    color: mat.get-color-from-palette($gp-palette, 500) !important;
    &:hover {
      color: mat.get-color-from-palette($gp-palette, 300) !important;
    }
  }
  h4 {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: mat.get-color-from-palette($gp-palette, 600);
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
  font-size: 14px;
  font-weight: 500;
  //styleName: Body/14/Regular;
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;

  .truncate-text-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: justify;
  }
  .truncate-text-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: justify;
  }
  .truncate-text-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: justify;
  }
}
textarea {
  resize: none;
}
.mat-mdc-list-item-title {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: mat.get-color-from-palette($gp-palette, 600) !important;
}
.mat-mdc-list-item-line {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: black !important;
}
.img-uploaded {
  position: relative;
  // max-height: 400px;
  overflow: hidden;
  background-color: #f8f9fa !important;
  border: 1px dashed var(--Gray-300, #ced4da);
  border-radius: 8px;
  max-width: fit-content;

  img {
    object-fit: cover;
  }

  span {
    position: absolute;
    font-size: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    visibility: hidden;
    color: mat.get-color-from-palette($gp-palette, 200);
    cursor: pointer;
  }
  &:hover {
    img {
      // opacity: .5;
      filter: brightness(70%);
    }
    span {
      visibility: visible;
    }
  }
}

//stores cat
.store-cat-papelaria {
  background-color: #efcaaf !important;
  .mdc-evolution-chip__text-label {
    color: #a0522d !important;
    font-weight: 500 !important;
  }
}
.store-cat-móveisedecoração {
  background-color: #dce3e8 !important;
  .mdc-evolution-chip__text-label {
    color: #5b7282 !important;
    font-weight: 500 !important;
  }
}
.store-cat-lazer {
  background-color: #dce3e8 !important;
  .mdc-evolution-chip__text-label {
    color: #5b7282 !important;
    font-weight: 500 !important;
  }
}
.store-cat-belezaecuidadospessoais {
  background-color: #f7daed !important;
  .mdc-evolution-chip__text-label {
    color: #cc1d91 !important;
    font-weight: 500 !important;
  }
}
.store-cat-lojadedepartamento {
  background-color: #dce3e8 !important;
  .mdc-evolution-chip__text-label {
    color: #5b7282 !important;
    font-weight: 500 !important;
  }
}
.store-cat-alimentação {
  background-color: #fadcd9 !important;
  .mdc-evolution-chip__text-label {
    color: #d91f11 !important;
    font-weight: 500 !important;
  }
}
.store-cat-eletrodomésticos {
  background-color: #dce3e8 !important;
  .mdc-evolution-chip__text-label {
    color: #5b7282 !important;
    font-weight: 500 !important;
  }
}
.store-cat-acessórios {
  background-color: #ffd1dc !important;
  .mdc-evolution-chip__text-label {
    color: #c9274e !important;
    font-weight: 500 !important;
  }
}
.store-cat-serviços {
  background-color: #dce3e8 !important;
  .mdc-evolution-chip__text-label {
    color: #5b7282 !important;
    font-weight: 500 !important;
  }
}
.store-cat-calçados {
  background-color: #dce3e8 !important;
  .mdc-evolution-chip__text-label {
    color: #5b7282 !important;
    font-weight: 500 !important;
  }
}
.store-cat-livraria {
  background-color: #ffd3c2 !important;
  .mdc-evolution-chip__text-label {
    color: #cd5c5c !important;
    font-weight: 500 !important;
  }
}
.store-cat-joalherias {
  background-color: #faf6cf !important;
  .mdc-evolution-chip__text-label {
    color: #cda309 !important;
    font-weight: 500 !important;
  }
}
.store-cat-esportes {
  background-color: #d4e4fa !important;
  .mdc-evolution-chip__text-label {
    color: #186ade !important;
    font-weight: 500 !important;
  }
}
.store-cat-eletrônicos {
  background-color: #e7f4eb !important;
  .mdc-evolution-chip__text-label {
    color: #377e4f !important;
    font-weight: 500 !important;
  }
}
.store-cat-modafeminina {
  background-color: #fcddc7 !important;
  .mdc-evolution-chip__text-label {
    color: #e86427 !important;
    font-weight: 500 !important;
  }
}
.store-cat-modamasculina {
  background-color: #c7e8ed !important;
  .mdc-evolution-chip__text-label {
    color: #067a91 !important;
    font-weight: 500 !important;
  }
}
.store-cat-modainfantil {
  background-color: #c7ebd1 !important;
  .mdc-evolution-chip__text-label {
    color: #077d55 !important;
    font-weight: 500 !important;
  }
}


//inputs
.mdc-text-field--invalid{
  background-color: rgb(255, 245, 245)
}


//datetime picker
.owl-dt-container-info .owl-dt-container-info-active{
  color: mat.get-color-from-palette($gp-palette, 400) !important;
}
.owl-dt-calendar-view .owl-dt-calendar-table .owl-dt-calendar-cell-selected{
  background-color: mat.get-color-from-palette($gp-palette, 400) !important;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range{
  background: mat.get-color-from-palette($gp-palette, 200) !important;
}
.owl-dt-container-buttons{
  color: mat.get-color-from-palette($gp-palette, 500) !important;
}



@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}


.report-container {
  height: 100%;
  width: 100%;
}
